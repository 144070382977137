export const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
export const formatPlateInfoDateString = (date: string) => {
    if (!date || date.length < 8) {
        console.error("Invalid date format or undefined date string");
        return ""; // or throw an error or return a default value
    }

    if(date.includes('/')) return date

    let years = date.substring(0, 4);
    let month = date.substring(4, 6);
    let day = date.substring(6);
    return `${day} / ${month} / ${years}`;
};

export const normalizeTitle = (title: string) => {
    return title
        .normalize("NFD") // Normaliser pour décomposer les accents
        .replace(/[\u0300-\u036f]/g, "") // Supprimer les marques diacritiques
        .replace(/[^a-zA-Z0-9 ]/g, "") // Supprimer les caractères non alphanumériques
        .trim() // Supprimer les espaces en début et fin de chaîne
        .replace(/\s+/g, "_") // Remplacer les espaces par des tirets
        .toLowerCase(); // Convertir en minuscules
}